import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import $ from 'jquery'
import { isMobile } from '@/assets/js/utils'
import VideoComponent from  '@/components/video.vue'
import * as PIXI from 'pixi.js'
Vue.config.productionTip = false
Vue.prototype.$isMobile = isMobile
Vue.component('video-pop', VideoComponent)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
window.$ = $
window.PIXI = PIXI
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
