<template>
  <div class="index">
    <div class="footer">
      <div class="icon"></div>
      <div class="text">
        <p>本公司游戏适合16周岁以上玩家！</p>
        <p>抵制不良游戏 拒绝盗版游戏 注意自我保护<br> 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 <br>合理安排时间 享受健康生活</p>
        <p>粤ICP备2022122871号</p>
        <p>Copyright 2018-2022 © mobaogame.com All Rights Reserved.</p>
        <div style="width:300px;margin:0 auto; padding:20px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502002494"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src=""
              style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备
              44010502002494号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      };
    },
    components: {},
    methods: {
    },
    mounted () {
    },
  };
</script>

<style lang="less" scoped>
  @import url(./index.less);
</style>