
import index from '@/views/m/index'
const mRoutes = [
  {
    path: '/',
    name: 'index',
    component: index
  }
];

export default mRoutes;