const PIXI = require('pixi.js')
export default function titleFrame(
    options = { width: 370, height: 840 },
    params = {
        imgKey: '标题',
        name: 'titleFrame',
        path: '/img/title/title.json',
        wrapEl: '.main-title',
        speed: 0.25,
        loop: true,
        fn: () => {},
        setup: () => {},
        onComplete: () => {}
    },
) {
    const publicPath = process.env.VUE_APP_PUBLIC_PATH
    const staticPath = process.env.VUE_APP_STATIC_PATH
    const app = new PIXI.Application({
        transparent: true,
        ...options,
        backgroundAlpha: 0
    })
    let jsonPath = null
    const isObject = typeof params.path === 'object'
    let animatedCapguy = null
    if (isObject) {
        jsonPath = []
        for (let path of params.path) {
            const url = `${publicPath}${staticPath}${path}`
            jsonPath.push({
                url,
                name: path,
                crossOrigin: true
            })
        }
        app.loader
            .add(jsonPath)
            .load(setup)
    } else {
        jsonPath = `${publicPath}${staticPath}/img/title/title.json`
        app.loader
            .add(params.name, jsonPath)
            .load(setup)
    }
    function setup(loader) {
        // console.log(loader)
        let animations = []
        // console.log(loader.resources);
        if (isObject) {
            jsonPath.forEach(element => {
                let textureArr = loader.resources[element.name].spritesheet.animations[params.imgKey]
                animations.push(...textureArr)
            });
            //console.log(animations)
        } else {
            animations = loader.resources[params.name].spritesheet.animations[params.imgKey]
        }
        animatedCapguy = new PIXI.AnimatedSprite(animations);
        // animationSpeed:控制播放速度，赋值范围0~1，默认是1
        // 0~1表示按照帧频函数执行频率的百分之多少速度切换纹理
        // 帧频函数执行次数60秒，1就是每秒切换60张
        animatedCapguy.animationSpeed = params.speed;                  // 6 fps
        animatedCapguy.loop = params.loop;
        if (!params.loop) {
            animatedCapguy.onComplete = () => {
                params.onComplete && params.onComplete()
                // setTimeout(() => {
                //     animatedCapguy.gotoAndPlay(0)
                // }, delay || 1000)
            }
        }
        animatedCapguy.play();
      
        app.stage.addChild(animatedCapguy);
        params.setup && params.setup(animatedCapguy)
        params.fn && params.fn(app)
    }
    document.querySelector(params.wrapEl).appendChild(app.view)
    return animatedCapguy
}