import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state() {
        return {
            pageIndex: 0,
            mainSwiper: null,
            videoPop: false,
            showRole: ''
        }
    },
    mutations: {
        setRole(state, payload) {
            state.showRole = payload
        },
        initMainSwiper(state, payload) {
            state.mainSwiper = payload
        },
        setPageIndex(state, payload) {
            state.setPageIndex = payload
        },
        setVideoState(state, payload) {
            state.videoPop = payload
        }
    },
    actions: {
        openPop({commit}, url) {
            commit('setVideoState', true)
            $('#videoPop .video-box').html(`<video controls preload loop width="100%" src="${url}"></video>`)
            const $video = $('#videoPop video')
            if ($video.length) {
                $video[0].play()
            }
        },
        closePop({commit}) {
            commit('setVideoState', false)
            $('#videoPop').find('video').remove()
        },
        closeRole({commit}) {
            commit('setRole', '')
        }
    }
})
