<template>
  <div class="menu" :class="[isPC ? 'pc-menu' : 'm-menu', visible ? '' : 'hidebox']">
    <div class="menu-box">
      <div class="btn-box"></div>
      <div class="box" v-if="$store.state.mainSwiper">
        <a href="javascript:;" @click.stop="$store.state.mainSwiper.slideTo(0)"
          :class="{ clicked: activeIndex === 0 }">概念</a>
        <a href="javascript:;" @click.stop="$store.state.mainSwiper.slideTo(1)"
          :class="{ clicked: activeIndex === 1 }">成员</a>
        <a href="javascript:;" @click.stop="$store.state.mainSwiper.slideTo(2)"
          :class="{ clicked: activeIndex === 2 }"></a>
      </div>
    </div>
    <div class="menu-btn" @click="changeBox">
      <i></i><i></i>
    </div>
  </div>
</template>
<script>
  import { isMobile } from "@/assets/js/utils";
  export default {
    data () {
      return {
        isPC: !isMobile,
        visible: isMobile ? false : true
      };
    },
    computed: {
      activeIndex () {
        let index = 0
        if (this.$store.state.mainSwiper) {
          index = this.$store.state.mainSwiper.activeIndex
        } else {
          index = this.$store.state.mainSwiper.activeIndex
        }
        return index
      }
    },
    methods: {
      changeBox () {
        this.visible = !this.visible
      },
    },
  };
</script>

<style lang="less" scoped>
  .pc-menu {
    z-index: 3;
    position: fixed;
    top: 50%;
    right: 45px;
    transform: translateY(-50%);
    font-size: 16px;

    a {
      position: relative;
      // border: 1px solid #fff;
      display: block;
      width: 70px;
      height: 35px;
      color: rgba(255, 255, 255, 0.3);
      text-align: center;
      line-height: 35px;

      &:nth-child(1) {
        margin-bottom: 100px;
      }

      &::before {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid;
        border-color: #fff #fff transparent transparent;
      }

      &.clicked {
        color: rgba(255, 255, 255, 1);

        &::before {
          opacity: 1;
        }
      }
    }
  }

  .m-menu {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 264px;

    z-index: 2;

    &.hidebox {
      .menu-box {
        height: 0;
      }

      i {
        transform: rotate(0deg) !important;
      }

      .menu-btn {

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }

    .menu-btn {
      position: absolute;
      top: 30px;
      right: 20px;
      width: 44px;
      height: 35px;

      i {
        position: absolute;
        width: 100%;
        height: 5px;
        top: 50%;
        margin-top: -2.5px;
        background: #fff;
        transition: all 0.5s 0s ease;

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }

      &::after,
      &::before {
        position: absolute;
        width: 100%;
        height: 5px;
        content: '';
        background: #fff;
        // transition: all 0.5s 0s ease;
        opacity: 0;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    .menu-box {
      width: 100%;
      height: 100%;
      transition: all 1s ease;
      overflow: hidden;

      .btn-box {
        width: 210px;
        height: 88px;
        background-color: rgba(0, 0, 0, 0.8);
      }

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          position: relative;
          width: 210px;
          height: 88px;
          color: #bababa;
          text-align: center;
          line-height: 88px;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 36px;

          &::after {
            position: absolute;
            opacity: 0;
            top: 20px;
            right: 54px;
            content: "";
            border: 8px solid;
            border-color: #fff #fff transparent transparent;
          }

          &.clicked {
            // background-color: rgba(58, 57, 57, 0.7);
            background-image: linear-gradient(to right,
                rgba(58, 57, 57, 0.7) 50%,
                rgba(112, 112, 112, 0.7) 90%,
                rgba(160, 158, 158, 0.7) 100%);
            color: #fff;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 8px;
              height: 88px;
              background-color: #fff;
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>