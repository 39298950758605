<template>
  <div class="role">
    <audio class="musicBgm" preload src="@/assets/bgm.mp3">
      您的浏览器不支持播放音乐。
    </audio>
    <div class="img-box">
      <img src="@/assets/img/m/role1.png" class="role-img1" alt="" />
      <img src="@/assets/img/m/role2.png" class="role-img2" alt="" />
      <img src="@/assets/img/m/role3.png" class="role-img3" alt="" />
      <img src="@/assets/img/m/role4.png" class="role-img4" alt="" />
    </div>
    <div class="img-act">
      <div class="act-box1" id="act-box1"></div>
      <div class="act-box2" id="act-box2"></div>
      <div class="act-box3" id="act-box3"></div>
      <div class="act-box4" id="act-box4"></div>
    </div>
    <!-- 动画 点 -->
    <div class="spot">
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
      <div class="spot-comm"></div>
    </div>
    <div class="click-box">
      <div class="box1" data-name="ksRole" @click="clickRole($event, 1)"></div>
      <div class="box2" data-name="ymlRole" @click="clickRole($event, 2)"></div>
      <div class="box3" data-name="yyRole" @click="clickRole($event, 3)"></div>
      <div class="box4" data-name="ttmRole" @click="clickRole($event, 4)"></div>
    </div>
  </div>
</template>

<script>
import roleFrame from "@/assets/js/imgsJson.js";
import anime from "animejs/lib/anime.es.js";
export default {
  data() {
    return {
      ksRole: null,
      ymlRole: null,
      yyRole: null,
      ttmRole: null,
    };
  },
  components: {},
  methods: {
    toast() {
      $("body").append('<div class="toast-msg-m">敬请期待</div>');
      $(".toast-msg-m").fadeIn(500);
      setTimeout(() => {
        $(".toast-msg-m").fadeOut(800, () => {
          $(".toast-msg-m").remove();
        });
      }, 1000);
    },
    initRole() {
      $(".img-act .show").removeClass("show");
      $(".role-img .hide").removeClass("hide");
    },
    clickRole(event, index) {
      const actBox = $(".act-box" + index);
      // const roleMap = ['ttm', 'yml', 'll', 'ks']
      // this.$store.commit('setRole', roleMap[index - 1])
      const { name } = event.target.dataset;
      if (actBox.hasClass("show")) {
        // if ($(".img-act .show").length === 4) {
        // this.$store.state.mainSwiper.slideTo(0, 1000);
        actBox.removeClass("show");
        $(".role" + index).removeClass("hide");
        // this.initRole();
        // this.toast();
      } else {
        if (!$(".act-box" + index).hasClass("show")) {
          //播放bgm
          let audio = $(".musicBgm")[0];
          audio.currentTime = 0;
          audio.play();
          this[name].gotoAndPlay(0);
          $(".act-box" + index).addClass("show");
          $(".role" + index).addClass("hide");
        }
      }
    },
    //创建点动画
    createSpot(el) {
      let randomNum = anime.random(10000, 15000);
      anime({
        targets: el,
        translateY: {
          value: "-=" + anime.random(300, 500) + "px",
          duration: randomNum,
        },
        opacity: {
          value: 0,
          delay: randomNum - 6000,
          duration: randomNum - 8000,
        },
        easing: "linear",
        loop: true,
      });
    },
    //初始化动画
    initSpot() {
      //初始化点动画
      anime.set(".spot .spot-comm", {
        translateX: function () {
          return anime.random(0, 750);
        },
        translateY: function () {
          return anime.random(0, 1400);
        },
      });

      document.querySelectorAll(".spot-comm").forEach((el) => {
        this.createSpot(el);
      });
      this.createSpot(".spot .spot1");
    },
  },
  mounted() {
    const roleSize = { width: 272, height: 590 };
    roleFrame(roleSize, {
      imgKey: "role",
      name: "role1",
      path: ["/img/m/role/role1/role.json"],
      wrapEl: "#act-box1",
      speed: 0.8,
      setup: (obj) => {
        this.ksRole = obj;
      },
    });
    roleFrame(roleSize, {
      imgKey: "role",
      name: "role2",
      path: ["/img/m/role/role2/role.json"],
      wrapEl: "#act-box2",
      speed: 0.8,
      setup: (obj) => {
        this.ymlRole = obj;
      },
    });
    roleFrame(
      { width: 505, height: 655 },
      {
        imgKey: "role",
        name: "role3",
        path: ["/img/m/role/role3/role.json"],
        wrapEl: "#act-box3",
        speed: 0.8,
        setup: (obj) => {
          this.yyRole = obj;
        },
      }
    );
    roleFrame(
      { width: 348, height: 479 },
      {
        imgKey: "role",
        name: "role4",
        path: ["/img/m/role/role4/role.json"],
        wrapEl: "#act-box4",
        speed: 0.8,
        setup: (obj) => {
          this.ttmRole = obj;
        },
      }
    );
    this.initSpot();
  },
};
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>