import index from '@/views/pc/index'

const pcRoutes = [
    {
      path: '/',
      name: 'index',
      component: index
    },
  ];

export default pcRoutes;