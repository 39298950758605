<template>
  <div class="role">
    <div class="main-wrap">
      <audio class="musicBgm" src="@/assets/bgm.mp3">
        您的浏览器不支持播放音乐。
      </audio>
      <div class="role-img">
        <img src="@/assets/img/pc/role1.png" alt="" class="role1" />
        <img src="@/assets/img/pc/role2.png" alt="" class="role2" />
        <img src="@/assets/img/pc/role4.png" alt="" class="role4" />
        <img src="@/assets/img/pc/role3.png" alt="" class="role3" />
      </div>
      <div class="img-act">
        <div class="act-box1" id="act-box1"></div>
        <div class="act-box2" id="act-box2"></div>
        <div class="act-box4" id="act-box4"></div>
        <div class="act-box3" id="act-box3"></div>
      </div>
      <!-- 动画 点 -->
      <div class="spot">
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
      </div>
      <div class="click-box">
        <div
          class="box1"
          data-name="ksRole"
          @click="clickRole($event, 1)"
          @mouseenter="enterRole(1)"
          @mouseleave="leaveRole(1)"
        ></div>
        <div
          class="box2"
          data-name="ymlRole"
          @click="clickRole($event, 2)"
          @mouseenter="enterRole(2)"
          @mouseleave="leaveRole(2)"
        ></div>
        <div
          class="box3"
          data-name="yyRole"
          @click="clickRole($event, 3)"
          @mouseenter="enterRole(3)"
          @mouseleave="leaveRole(3)"
        ></div>
        <div
          class="box4"
          data-name="ttmRole"
          @click="clickRole($event, 4)"
          @mouseenter="enterRole(4)"
          @mouseleave="leaveRole(4)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import roleFrame from "@/assets/js/imgsJson.js";
import anime from "animejs/lib/anime.es.js";

export default {
  data() {
    return {
      ksRole: null,
      ymlRole: null,
      yyRole: null,
      ttmRole: null,
    };
  },
  components: {},
  methods: {
    enterRole(index) {
      $(".role" + index).addClass("op-half");
    },
    leaveRole(index) {
      $(".role" + index).removeClass("op-half");
    },
    initRole() {
      $(".img-act .show").removeClass("show");
      $(".role-img .hide").removeClass("hide");
    },
    toast() {
      $("body").append('<div class="toast-msg-pc">敬请期待</div>');
      $(".toast-msg-pc").fadeIn(500);
      setTimeout(() => {
        $(".toast-msg-pc").fadeOut(1000, () => {
          $(".toast-msg-pc").remove();
        });
      }, 800);
    },
    clickRole(event, index) {
      // let audio = $(".musicBgm")[0];
      // audio.currentTime = 0;
      // audio.play();
      const actBox = $(".act-box" + index);
      // const roleMap = ['ttm', 'yml', 'll', 'ks']
      // this.$store.commit('setRole', roleMap[index - 1])
      const { name } = event.target.dataset;
      if (actBox.hasClass("show")) {
        // if($('.img-act .show').length === 4){
        // this.$store.state.mainSwiper.slideTo(0, 1000);
        // this.initRole();
        actBox.removeClass("show");
        $(".role" + index).removeClass("hide");
        // this.$message({
        //   showClose: true,
        //   message: '敬请期待'
        // });
        // this.toast();
      } else {
        if (!$(".act-box" + index).hasClass("show")) {
          //播放bgm
          let audio = $(".musicBgm")[0];
          audio.currentTime = 0;
          audio.play();
          this[name].gotoAndPlay(0);
          $(".act-box" + index).addClass("show");
          $(".role" + index).addClass("hide");
        }
      }
    },
    //创建点动画
    createSpot(el) {
      let randomNum = anime.random(10000, 15000);
      anime({
        targets: el,
        translateY: {
          value: "-=" + anime.random(300, 500) + "px",
          duration: randomNum,
        },
        opacity: {
          value: 0,
          delay: randomNum - 6000,
          duration: randomNum - 8000,
        },
        easing: "linear",
        loop: true,
      });
    },
    //初始化动画
    initSpot() {
      //初始化点动画
      anime.set(".spot .spot-comm", {
        translateX: function () {
          return anime.random(0, 1900);
        },
        translateY: function () {
          return anime.random(0, 900);
        },
      });

      document.querySelectorAll(".spot-comm").forEach((el) => {
        this.createSpot(el);
      });
      this.createSpot(".spot .spot1");
    },
  },
  mounted() {
    roleFrame(
      { width: 372, height: 970 },
      {
        imgKey: "role",
        name: "role1",
        path: ["/img/pc/role/role1/role.json"],
        wrapEl: "#act-box1",
        speed: 0.8,
        setup: (obj) => {
          this.ksRole = obj;
        },
      }
    );
    roleFrame(
      { width: 234, height: 970 },
      {
        imgKey: "role",
        name: "role2",
        path: ["/img/pc/role/role2/role.json"],
        wrapEl: "#act-box2",
        speed: 0.8,
        setup: (obj) => {
          this.ymlRole = obj;
        },
      }
    );
    roleFrame(
      { width: 732, height: 970 },
      {
        imgKey: "role",
        name: "role3",
        path: ["/img/pc/role/role3/role.json"],
        wrapEl: "#act-box3",
        speed: 0.8,
        setup: (obj) => {
          this.yyRole = obj;
        },
      }
    );
    roleFrame(
      { width: 492, height: 970 },
      {
        imgKey: "role",
        name: "role4",
        path: ["/img/pc/role/role4/role.json"],
        wrapEl: "#act-box4",
        speed: 0.8,
        setup: (obj) => {
          this.ttmRole = obj;
        },
      }
    );
  },
};
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>