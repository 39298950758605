<template>
    <div class="popup" id="videoPop" :class="[$isMobile ? 'mobile-pop' : 'pc-pop', $store.state.videoPop ? 'show' : '']">
        <div class="mask" @click.stop="$store.dispatch('closePop')"></div>
        <div class="popup-content">
            <div class="video-box">
                <video  src=""></video>
            </div>
            <div class="close-btn" @click.stop="$store.dispatch('closePop')"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
}
</script>
<style lang="less" scoped>
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 99;
    &.show{
        display: flex;
    }
    .mask{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
    }
    .close-btn{
        position: absolute;
        cursor: pointer;
    }
    .video-box{
        width: 100%;
        height: 100%;
        video{
            width: 100%;
        }
    }
    .popup-content{
        position: relative;
        z-index: 1;
    }
}
.mobile-pop{
    .close-btn{
        bottom: -112px;
        right: 50%;
        margin-right: -47.5px;
        width: 95px;
        height: 96px;
        background: url(~@/assets/img/pc/close-btn.png);
        transition: all 0.5s 0s ease;
    }
}
.pc-pop{
    .popup-content{
        
        width: 985px;
        height: 554px;
        background: #000;
        .close-btn{
            top: 0;
            right: -96px;
            width: 95px;
            height: 96px;
            background: url(~@/assets/img/pc/close-btn.png);
            transition: all 0.5s 0s ease;
            &:hover{
                transform: rotate(180deg);
            }
        }
    }

}
</style>