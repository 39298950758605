<template>
  <div class="index">
    <!-- <div class="main-wrap"> -->
    <!-- <audio class="musicBgm" src="@/assets/bgm.mp3">
        您的浏览器不支持播放音乐。
      </audio> -->
    <!-- <div class="role-img">
        <img src="@/assets/img/pc/role1.png" alt="" class="role1" />
        <img src="@/assets/img/pc/role2.png" alt="" class="role2" />
        <img src="@/assets/img/pc/role4.png" alt="" class="role4" />
        <img src="@/assets/img/pc/role3.png" alt="" class="role3" />
      </div>
      <div class="img-act">
        <div class="act-box1" id="act-box1"></div>
        <div class="act-box2" id="act-box2"></div>
        <div class="act-box4" id="act-box4"></div>
        <div class="act-box3" id="act-box3"></div>
      </div>
      <div class="spot">
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
        <div class="spot-comm"></div>
      </div> -->
    <!-- <div class="click-box">
        <div class="box1" data-name="ksRole" @click="clickRole($event, 1)" @mouseenter="enterRole(1)"
          @mouseleave="leaveRole(1)"></div>
        <div class="box2" data-name="ymlRole" @click="clickRole($event, 2)" @mouseenter="enterRole(2)"
          @mouseleave="leaveRole(2)"></div>
        <div class="box3" data-name="yyRole" @click="clickRole($event, 3)" @mouseenter="enterRole(3)"
          @mouseleave="leaveRole(3)"></div>
        <div class="box4" data-name="ttmRole" @click="clickRole($event, 4)" @mouseenter="enterRole(4)"
          @mouseleave="leaveRole(4)"></div>
      </div> -->

    <!-- </div> -->
    <div class="footer">
      <div class="icon"></div>
      <div class="text">
        <p>本公司游戏适合16周岁以上玩家！</p>
        <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
        <p>粤ICP备2022122871号</p>
        <p>Copyright 2018-2022 © mobaogame.com All Rights Reserved.</p>
        <div style="width:300px;margin:0 0; padding:0px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502002494"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src=""
              style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 0px; color:#939393;">粤公网安备
              44010502002494号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data () {
      return {

      };
    },
    components: {},
    methods: {

    },
    mounted () {

    },
  };
</script>

<style lang="less" scoped>
  @import url(./index.less);
</style>