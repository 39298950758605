<template>
  <div class="home">
    <audio class="musicBg" src="@/assets/musicBg.mp3" preload loop="loop">
      您的浏览器不支持播放音乐。
    </audio>

    <swiper ref="mainSwiper" class="main-swiper" :options="mainSwiperConfig">
      <swiper-slide v-if="false">
        <kv-view></kv-view>
      </swiper-slide>
      <swiper-slide>
        <div class="index-view">
          <!-- 动画 点 -->
          <div class="spot">
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
          </div>
          <div id="s8Ani" class="pic" @click="slideChange(1)"></div>
          <div class="zs-bottom"></div>
          <div class="s8-title">
            <div class="down-ani"></div>
          </div>
        </div>
        <div class="lt-logo"></div>
        <div class="tabbtn-group" v-if="false">
          <ul>
            <li class="ylf curr"></li>
            <li class="nayy"></li>
            <li class="nhkj"></li>
            <li class="jhz"></li>
            <li class="st"></li>
            <li class="cx"></li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide>
        <role-view ref="roleView"></role-view>
        <div class="role-lt"></div>
      </swiper-slide>
      <swiper-slide>
        <footer-view></footer-view>
      </swiper-slide>
    </swiper>
    <main-menu></main-menu>
    <div class="ctrl-bgMusic" @click="closeMusic" :class="{ closed: !musicStaic }"></div>
    <a class="s8-logo" href="https://xinyuan.miaozilla.com/"></a>
    <role-info v-if="false"></role-info>
  </div>
</template>

<script>
  import mainMenu from '@/components/menu.vue'
  import RoleView from '../role/index.vue'
  import footerView from '../footer/index.vue'
  import KvView from '../kv/index.vue'
  import RoleInfo from '../roleInfo/index.vue'
  import anime from 'animejs/lib/anime.es.js'
  import spritesheet from '@/assets/js/spritesheet.js'
  export default {
    name: 'home',
    data () {
      return {
        mainSwiperConfig: {
          observeParents: true,
          observer: true,
          // mousewheelControl: true,
          direction: 'vertical',
          onInit: (swiper) => {
            //Swiper初始化了
            this.mainSwiper = swiper
          },
          onSlideChangeEnd: (swiper) => {
            this.$refs.roleView.initRole()
            console.log(swiper)
            //锁住白边
            // switch (swiper.activeIndex) {
            //   case 0:
            //     swiper.lockSwipeToPrev()
            //     swiper.unlockSwipeToNext()
            //     break
            //   case 1:
            //     swiper.lockSwipeToNext()
            //     swiper.unlockSwipeToPrev()
            //     break
            // }
          },
        },
        mainSwiper: null,
        musicStaic: false,
        bgMusic: null,
      }
    },
    watch: {
      mainSwiper: function (val) {
        this.$store.commit('initMainSwiper', val)
      },
    },
    mounted () {
      spritesheet({
        animationSpeed: 0.4,
        width: 626,
        height: 462,
        el: '#s8Ani',
        spritesheetName: '/img/common/circle-0.json',
      })
      this.initSpot()
      // 背景音乐
      this.bgMusic = $('.musicBg')[0]
      $('body').one('click', () => {
        this.bgMusic.play()
        this.musicStaic = true
      })
    },
    methods: {
      closeMusic () {
        if (this.bgMusic) {
          if (this.musicStaic) {
            this.bgMusic.pause()
            this.musicStaic = false
          } else {
            this.bgMusic.play()
            this.musicStaic = true
          }
        }
      },
      slideChange (index) {
        this.$store.state.mainSwiper.slideTo(index)
      },
      //创建点动画
      createSpot (el) {
        let randomNum = anime.random(10000, 15000)
        anime({
          targets: el,
          translateY: {
            value: '-=' + anime.random(300, 500) + 'px',
            duration: randomNum,
          },
          opacity: {
            value: 0,
            delay: randomNum - 6000,
            duration: randomNum - 8000,
          },
          easing: 'linear',
          loop: true,
        })
      },
      //初始化动画
      initSpot () {
        //初始化点动画
        anime.set('.spot .spot-comm', {
          translateX: function () {
            return anime.random(0, 750)
          },
          translateY: function () {
            return anime.random(0, 1400)
          },
        })

        document.querySelectorAll('.spot-comm').forEach((el) => {
          this.createSpot(el)
        })
        this.createSpot('.spot .spot1')
      },
    },
    components: {
      RoleInfo,
      KvView,
      mainMenu,
      RoleView,
      footerView,
    },
  }
</script>

<style lang="less" scoped>
  @import url(./index.less);
</style>