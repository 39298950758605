<template>
    <div class="role-info-main" :data-name="$store.state.showRole">
        <div class="close-btn" @click.stop="$store.dispatch('closeRole')"></div>
        <div class="role-item" v-for="(role, index) of roleList" :class="role.name" :key="index">
            <div class="role-name"></div>
            <div class="role-text"></div>
            <div class="role-ani">
                <div class="role-ani-box"></div>
            </div>
            <div class="role-angle"></div>
        </div>
    </div>
</template>
<script>
import roleFrame from '@/assets/js/imgsJson.js'
export default {
    data() {
        return {
            roleList: [
                {
                    name: 'ks'
                },
                {
                    name: 'll'
                },
                {
                    name: 'ttm'
                },
                {
                    name: 'yml'
                }
            ]
        }
    },
    mounted() {
        const frameSize = {
            width: 750,
            height: 1624
        }
        roleFrame(
            frameSize,
            {
                imgKey: 'ks',
                name: 'ks-ani',
                path: ['/img/m/role-info/ks/role-0.json', '/img/m/role-info/ks/role-1.json'],
                wrapEl: '.role-info-main .role-item.ks .role-ani-box',
                speed: 0.4,
                fn: () => {},
            }
        )
        roleFrame(
            frameSize,
            {
                imgKey: 'yml',
                name: 'yml-ani',
                path: ['/img/m/role-info/yml/role-0.json', '/img/m/role-info/yml/role-1.json', '/img/m/role-info/yml/role-2.json'],
                wrapEl: '.role-info-main .role-item.yml .role-ani-box',
                speed: 0.4,
                fn: () => {},
            }
        )
        roleFrame(
            frameSize,
            {
                imgKey: 'll',
                name: 'll-ani',
                path: ['/img/m/role-info/ll/role-0.json', '/img/m/role-info/ll/role-1.json', '/img/m/role-info/ll/role-2.json'],
                wrapEl: '.role-info-main .role-item.ll .role-ani-box',
                speed: 0.4,
                fn: () => {},
            }
        )
        roleFrame(
            frameSize,
            {
                imgKey: 'ttm',
                name: 'ttm-ani',
                path: ['/img/m/role-info/ttm/role-0.json', '/img/m/role-info/ttm/role-1.json'],
                wrapEl: '.role-info-main .role-item.ttm .role-ani-box',
                speed: 0.4,
                fn: () => {},
            }
        )
    },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
</style>