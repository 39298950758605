const publicPath = process.env.VUE_APP_PUBLIC_PATH
const staticPath = process.env.VUE_APP_STATIC_PATH
class Spritesheet {
    hasPath = true
    // 默认配置参数
    config = {
        transparent: true,
        width: 1624,
        height: 750,
        el: '',
        spritesheetName: 'spritesheetName.json',
        animationSpeed: 0.126,
        loop: true,
        autoPlay: true,
        onComplete: () => {},
        init: () => {}
    }
    
    constructor(options = {}) {
        this.options = Object.assign(this.config, options)
        this.options.spritesheetName = this.hasPath ? `${publicPath}${staticPath}${this.options.spritesheetName}` : this.options.spritesheetName
        this.el = document.querySelector(this.options.el)
        this.runnerAnimation = {}
        this.init()
    }
    app = null
    init() {
        const { width, height, transparent, spritesheetName } = this.options
        this.app = new PIXI.Application({ width, height, transparent })
        this.el.appendChild(this.app.view)
        this.app.loader.add(spritesheetName).load(this.setup.bind(this))
    }
    setup(loader) {
        const { spritesheetName } = this.options
        const resources = loader.resources[spritesheetName]
        console.log(resources)
        const multiPack = resources.data.meta.related_multi_packs
        let multiPackTexttureMap = { ...resources.spritesheet.textures }
        // resources.data.meta.image.replace(/-\d*.(jpg|png)/, '')
        let motion = ''
        for (let key in resources.data.animations) {
            motion = key
            break
        }
        const animationSeq = [...resources.data.animations[motion]]
        if (multiPack && multiPack.length) {
            multiPack.forEach((mpack) => {
                const textureName = mpack.replace('.json', '')
                const currResources = loader.resources[textureName]
                const _mpackSheet = currResources.spritesheet
                if (currResources.data.animations[motion]) {
                    animationSeq.push(...currResources.data.animations[motion])
                }
                if (_mpackSheet.textures) {
                  multiPackTexttureMap = Object.assign(
                    multiPackTexttureMap,
                    _mpackSheet.textures
                  )
                }
            })
            animationSeq.sort()
        }
        const animationSeqTextures = animationSeq.map(frame => multiPackTexttureMap[frame])
        this.createRunAnimation(animationSeqTextures)
        if (this.options.autoPlay) {
            this.runnerAnimation.play()
        }
    }
    createRunAnimation(animationArray) {
        this.runnerAnimation = new PIXI.AnimatedSprite(animationArray)
        this.runnerAnimation.animationSpeed = this.options.animationSpeed
        //runnerAnimation.position.set(0, 0); // almost bottom-left corner of the canvas
        this.runnerAnimation.loop = this.options.loop
        this.runnerAnimation.onComplete = () => {
            this.options.onComplete()
        }
        this.app.stage.addChild(this.runnerAnimation)
        this.options.init(this.runnerAnimation)
    }
}

export default function spritesheet(options) {
    return new Spritesheet(options)
}