// 倒计时
export function countTime(time = 45) {
    let curTime = time * 1000;
    const delay = 1000;
    let timeState = null;
    function timeRun(cb) {
        // console.log(curTime)
        if (curTime <= 0) {
            curTime = time * 1000
            clearTimeout(timeState)
            return
        }
        curTime -= delay
        cb && cb(curTime)
        timeState = setTimeout(function() {
            timeRun(cb)
        }, delay)
    }
    return timeRun
}
export const UA = navigator.userAgent
export const isMobile = UA.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null
export const isIOS = !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
export const isAndroid = UA.indexOf('Android') > -1 || UA.indexOf('Linux') > -1
export let devOs = isMobile ? (isIOS ? 'ios' : 'android') : 'pc'
export const objType = (obj) => {
    let typeStr = ''
    typeStr = Object.prototype.toString.call(obj)
    return typeStr.replace(/\[object (\w+)\]/, '$1')
}
export function debounce(fn, wait = 50) {
    // 通过闭包缓存一个定时器 id
    let timer = null
    // 将 debounce 处理结果当作函数返回
    // 触发事件回调时执行这个返回函数
    return function(...args) {
        // 如果已经设定过定时器就清空上一次的定时器
        if (timer) clearTimeout(timer)
        // 开始设定一个新的定时器，定时器结束后执行传入的函数 fn
        timer = setTimeout(() => {
            fn.apply(this, args)
        }, wait)
    }
}
