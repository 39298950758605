import Vue from 'vue'
import VueRouter from 'vue-router'
import pcRoutes from './pc/index'
import mRoutes from './m/index'
import { isMobile } from '@/assets/js/utils'
Vue.use(VueRouter)
// const uri = isMobile ? 'm' : 'pc' 
const routes = isMobile ? mRoutes : pcRoutes;
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL + uri,
  routes,
})

export default router
