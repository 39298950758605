<template>
  <div class="main">
    <audio class="musicBg" src="@/assets/musicBg.mp3" loop="loop">
      您的浏览器不支持播放音乐。
    </audio>
    <div class="ctrl-bgMusic" @click="closeMusic" :class="{ closed: !musicStaic }"></div>
    <a class="s8-logo" href="https://xinyuan.miaozilla.com/" target="_blank"></a>
    <swiper ref="mainSwiper" class="main-swiper" :options="mainSwiperConfig">
      <swiper-slide v-if="false">
        <kv-view></kv-view>
      </swiper-slide>
      <!-- <swiper-slide class="swiper-no-swiping"> -->
      <swiper-slide>
        <div class="index-view">
          <!-- 动画 点 -->
          <div class="spot">
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
            <div class="spot-comm"></div>
          </div>
          <div class="white-line">
            <div class="wrap">
              <div class="box1">
                <div class="line-box">
                  <img src="~@/assets/img/pc/line/w1.png" class="wl-img" alt="" />
                  <img src="~@/assets/img/pc/line/w2.png" class="wl-img" alt="" />
                  <img src="~@/assets/img/pc/line/w3.png" class="wl-img" alt="" />
                  <img src="~@/assets/img/pc/line/w4.png" class="wl-img" alt="" />
                  <img src="~@/assets/img/pc/line/w5.png" class="wl-img" alt="" />
                </div>
              </div>
            </div>
            <div class="wrap">
              <div class="box2">
                <div class="line-box">
                  <img src="~@/assets/img/pc/line/w1.png" class="wr-img" alt="" />
                  <img src="~@/assets/img/pc/line/w2.png" class="wr-img" alt="" />
                  <img src="~@/assets/img/pc/line/w3.png" class="wr-img" alt="" />
                  <img src="~@/assets/img/pc/line/w4.png" class="wr-img" alt="" />
                  <img src="~@/assets/img/pc/line/w5.png" class="wr-img" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="purple-line">
            <div class="wrap">
              <div class="box1">
                <div class="line-box">
                  <img src="~@/assets/img/pc/line/p1.png" class="pl-img" alt="" />
                  <img src="~@/assets/img/pc/line/p2.png" class="pl-img" alt="" />
                  <img src="~@/assets/img/pc/line/p3.png" class="pl-img" alt="" />
                  <img src="~@/assets/img/pc/line/p4.png" class="pl-img" alt="" />
                  <img src="~@/assets/img/pc/line/p5.png" class="pl-img" alt="" />
                </div>
              </div>
            </div>
            <div class="wrap">
              <div class="box2">
                <div class="line-box">
                  <img src="~@/assets/img/pc/line/p1.png" class="pr-img" alt="" />
                  <img src="~@/assets/img/pc/line/p2.png" class="pr-img" alt="" />
                  <img src="~@/assets/img/pc/line/p3.png" class="pr-img" alt="" />
                  <img src="~@/assets/img/pc/line/p4.png" class="pr-img" alt="" />
                  <img src="~@/assets/img/pc/line/p5.png" class="pr-img" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="pic-box">
            <div id="s8Ani" class="pic" @click="slideChange(1)"></div>
            <div class="s8-title">
              <div class="down-ani"></div>
            </div>
          </div>
          <div class="bottom-ani"></div>
          <div class="grain-bottom"></div>
        </div>

        <div class="tabbtn-group" v-if="false">
          <ul>
            <li class="ylf curr"></li>
            <li class="nayy"></li>
            <li class="nhkj"></li>
            <li class="jhz"></li>
            <li class="st"></li>
            <li class="cx"></li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide>
        <role-view ref="roleView"></role-view>
      </swiper-slide>
      <swiper-slide v-if="true">
        <footer-view></footer-view>
      </swiper-slide>
    </swiper>
    <main-menu></main-menu>
    <role-info v-if="false"></role-info>
  </div>
</template>
<script>
  import mainMenu from '@/components/menu.vue'
  import RoleView from '../role/index.vue'
  import footerView from '../footer/index.vue'
  import RoleInfo from '../roleInfo/index.vue'
  import KvView from '../kv/index.vue'
  import anime from 'animejs/lib/anime.es.js'
  import spritesheet from '@/assets/js/spritesheet.js'
  export default {
    data () {
      return {
        mainSwiperConfig: {
          observeParents: true,
          observer: true,
          mousewheelControl: true,
          direction: 'vertical',
          // freeMode : false,
          // effect: "fade",
          // noSwiping: true,
          onInit: (swiper) => {
            //Swiper初始化了
            this.mainSwiper = swiper
            // swiper.lockSwipeToPrev()
            // swiper.unlockSwipeToNext()
          },
          onSlideChangeEnd: (swiper) => {
            this.$refs.roleView.initRole()
            console.log(swiper.activeIndex);
            //锁住白边
            // switch (swiper.activeIndex) {
            // case 0:
            //   swiper.lockSwipeToPrev()
            //   swiper.unlockSwipeToNext()
            //   break
            // case 1:
            //   swiper.lockSwipeToNext()
            //   swiper.unlockSwipeToPrev()
            //   break
            // }
          },
        },
        mainSwiper: null,
        musicStaic: false,
        bgMusic: null,
      }
    },
    watch: {
      mainSwiper: function (val) {
        this.$store.commit('initMainSwiper', val)
      },
    },
    mounted () {
      spritesheet({
        animationSpeed: 0.4,
        width: 626,
        height: 462,
        el: '#s8Ani',
        spritesheetName: '/img/pc/circle/s8-0.json',
      })
      this.initSpot()
      // 背景音乐
      this.bgMusic = $('.musicBg')[0]
      $('body').one('click', () => {
        this.bgMusic.play()
        this.musicStaic = true
      })
    },
    methods: {
      slideChange (index) {
        this.$store.state.mainSwiper.slideTo(index)
      },
      closeMusic () {
        if (this.bgMusic) {
          if (this.musicStaic) {
            this.bgMusic.pause()
            this.musicStaic = false
          } else {
            this.bgMusic.play()
            this.musicStaic = true
          }
        }
      },
      // 初始化折现动画 el为每个图片的公用类名
      createLine (el, duration, offersetX) {
        let xTrans = []
        anime.set(el, {
          translateX: function () {
            let i = arguments[1]
            // console.log(arguments[1]);
            xTrans[i] = { x: i * 640 }
            return i * 640 - offersetX
          },
        })

        anime({
          targets: xTrans,
          duration: duration,
          easing: 'linear',
          x: '+=3200',
          loop: true,
          update: function () {
            anime.set(el, {
              translateX: function () {
                let i = arguments[1]
                return xTrans[i].x % 3200
              },
            })
          },
        })
      },
      //创建点动画
      createSpot (el) {
        let randomNum = anime.random(10000, 15000)
        anime({
          targets: el,
          translateY: {
            value: '-=' + anime.random(300, 500) + 'px',
            duration: randomNum,
          },
          opacity: {
            value: 0,
            delay: randomNum - 6000,
            duration: randomNum - 8000,
          },
          easing: 'linear',
          loop: true,
        })
      },
      //初始化动画
      initSpot () {
        //初始化点动画
        anime.set('.spot .spot-comm', {
          translateX: function () {
            return anime.random(0, 1900)
          },
          translateY: function () {
            return anime.random(0, 900)
          },
        })

        document.querySelectorAll('.spot-comm').forEach((el) => {
          this.createSpot(el)
        })
        this.createSpot('.spot .spot1')

        //初始化折线图
        this.createLine('.wl-img', 100000, 1000)
        this.createLine('.wr-img', 63000, 0)
        this.createLine('.pl-img', 85000, 0)
        this.createLine('.pr-img', 69000, 0)
      },
    },
    components: {
      RoleInfo,
      KvView,
      mainMenu,
      RoleView,
      footerView,
    },
  }
</script>
<style lang="less" scoped>
  @import url(./index.less);
</style>